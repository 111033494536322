/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/img-redundant-alt */

"use client"

import { useCallback, useEffect, useState } from "react"
import Link from "next/link"
import clsx from "clsx"
import { Widget } from "@typeform/embed-react"

import "styles/retron.css"

import RDLogoIcon from "icons/RDLogo"
import { H3, HeadingMontreal, SerifH7, Small } from "styles/Type"
import Button from "@/components/Button"
import * as Dialog from "@radix-ui/react-dialog"
import CloseIcon from "@/icons/Close"
import Head from "next/head"

const TYPEFORM_ID = "Uk4EyaF3?typeform-welcome=0"

export default function RootPageClient() {
  const images = [
    "/assets/images/onboarding/onboarding_1.png",
    "/assets/images/onboarding/onboarding_2.png",
    "/assets/images/onboarding/onboarding_3.png",
    "/assets/images/onboarding/onboarding_4.png",
    "/assets/images/onboarding/onboarding_5.png",
    "/assets/images/onboarding/onboarding_6.png",
    "/assets/images/onboarding/onboarding_7.png",
    "/assets/images/onboarding/onboarding_8.png",
    "/assets/images/onboarding/onboarding_9.png",
    "/assets/images/onboarding/onboarding_10.png",
    "/assets/images/onboarding/onboarding_11.png",
    "/assets/images/onboarding/onboarding_12.png",
    "/assets/images/onboarding/onboarding_13.png",
    "/assets/images/onboarding/onboarding_14.png",
    "/assets/images/onboarding/onboarding_15.png",
  ]
  const [currentImage, setCurrentImage] = useState(0)
  const [isReversing, setIsReversing] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        if (!isReversing && prevImage === images.length - 1) {
          setIsReversing(true)
          return prevImage - 1
        }
        if (isReversing && prevImage === 0) {
          setIsReversing(false)
          return prevImage + 1
        }
        return isReversing ? prevImage - 1 : prevImage + 1
      })
    }, 1000) // Change image every 1 second

    return () => clearInterval(interval)
  }, [images.length, isReversing])

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = useCallback(() => {
    setIsSubmitted(true)
    setIsOpen(false)
  }, [])

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <Head>
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin=""
          href="/assets/fonts/retron2000.woff2"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin=""
          href="/assets/fonts/PPNeueMontreal-Bold.woff2"
        />
      </Head>

      <div
        className={clsx(
          "inset-0 right-auto flex flex-col text-center justify-between items-center p-7 bg-black",
          "fixed w-full h-full"
        )}
      >
        <div className="flex items-center absolute justify-center h-screen w-full bg-gray-200">
          <div className="relative w-full h-full mt-[-5%]">
            {images.map((image, index) => (
              <div
                key={index}
                className={`absolute inset-0 flex z-[20] items-center justify-center ${
                  index === currentImage ? "block" : "hidden"
                }`}
              >
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="w-auto h-auto"
                />
              </div>
            ))}
          </div>
        </div>
        <video
          className={clsx("fixed inset-0 h-full w-full object-cover")}
          src="/assets/videos/bg_video.mp4"
          autoPlay
          playsInline
          loop
          muted
        />
        <div className="absolute inset-0 bg-black opacity-10" />
        <div
          className={clsx(
            isSubmitted && "opacity-0 translate-y-20",
            "transition duration-500",
            "flex items-center justify-center",
            "w-full"
          )}
        >
          <RDLogoIcon width="107" height="43" className="relative z-[50]" />
        </div>
        <Small className="mt-5 z-50 absolute right-10 -translate-y-4 text-[#FFFFFF66]">
          Already have an account?{" "}
          <span className="text-white">
            <Link href="/login" passHref>
              Sign in
            </Link>
          </span>
        </Small>
        <div className="relative w-full">
          <div
            className={clsx(
              isSubmitted && "absolute opacity-0 translate-y-20",
              "transition duration-500  w-full flex justify-center",
              "w-full"
            )}
          >
            <div className="flex gap-2">
              <sup className="top-1">
                <Small>a</Small>
              </sup>
              <HeadingMontreal>collaboration discovery</HeadingMontreal>
              <sup className="top-1">
                <Small>for</Small>
              </sup>
              <HeadingMontreal>artists</HeadingMontreal>
            </div>
          </div>
          <div
            className={clsx(
              !isSubmitted && "opacity-0 absolute -translate-y-20 invisible",
              "transition-all duration-500",
              "w-full"
            )}
          >
            <div style={{ width: 465 }} className="mx-auto max-w-full">
              <RDLogoIcon width="107" height="43" className="mb-6 mx-auto" />
              <H3 as="p">
                Our membership team will review your submission <br />
                and be in touch shortly.
              </H3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div
            className={clsx(
              isSubmitted && "opacity-0 translate-y-20 absolute w-full",
              "transition duration-500"
            )}
          >
            <Dialog.Trigger asChild>
              <Button
                as="button"
                type="button"
                theme="white"
                className={clsx(
                  "mt-7 py-5 px-10 text-offblack  text-center text-base relative z-[99] font-normal font-['PP Neue Montreal'] leading-normal hover:bg-[#EEEBD7]"
                )}
                size="xl"
              >
                Join the waitlist
              </Button>
            </Dialog.Trigger>
          </div>
          <SerifH7
            as="p"
            className="text-whiteCream opacity-50 mt-6 relative z-[99]"
          >
            By artists, for artists. Los Angeles, CA
          </SerifH7>
          {/* <H6 as="address" className="not-italic -mb-0.5">
           
          </H6> */}
        </div>
      </div>
      {isOpen && <div className="fixed inset-0 bg-black opacity-50" />}
      <Dialog.Content
        forceMount
        className={clsx(
          "fixed right-0 top-0 h-full bg-offblack max-w-full z-[999]",
          !isOpen && "translate-x-full opacity-0",
          "transition duration-500",
          "flex flex-col px-6 md:px-0 justify-center",
          "mr-px"
        )}
        style={{ width: 420 }}
      >
        <div
          style={{
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? "auto" : "none",
            transition: "opacity 0.5s ease",
          }}
          className="fixed right-0 top-0  bg-offblack max-w-full z-[999] h-screen"
        >
          <Widget
            id={TYPEFORM_ID}
            hideHeaders
            opacity={0}
            onSubmit={onSubmit}
            className="flex flex-grow w-[420px] h-screen"
          />
        </div>
        <Dialog.Close
          style={{ outline: "none" }}
          className="lg:hidden absolute top-5 right-5 p-2"
          title="Close"
        >
          <CloseIcon width="24" height="24" />
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  )
}
